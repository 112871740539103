import React, {useEffect} from 'react';
import {ErrorBoundary} from 'mui-core';
import {getCardImgSet, getLogo} from 'core/utils';
import {defaultBannerImage, noCollageLogo} from 'assets/images';

const LazyImage = ({
  src,
  type,
  dataSrc,
  dataSrcSet,
  renderSrcSet,
  className = '',
  alt = 'banner',
  imageType = 'card',
  defaultImage = defaultBannerImage,
  sizes = `(max-width: 480px) 240px, (max-width: 980px) 480px, 960px`,
}) => {
  useEffect(() => {
    document.addEventListener(
      'error',
      function (e) {
        if (e.target.nodeName == 'IMG') {
          e.target.src = defaultImage;
        }
      },
      true,
    );
  }, [defaultImage]);

  if (imageType === 'card' && renderSrcSet) {
    const IMG = getCardImgSet(src, type);
    return (
      <ErrorBoundary nameOfComponent='core-lazyimage'>
        <img
          width='100%'
          height='100%'
          src={IMG?.defaultImage}
          //data-sizes='auto'
          data-src={IMG?.normalImage}
          alt={alt}
          className={`lazyload blur-up ${className}`}
          data-srcset={IMG?.srcSet}
          sizes={sizes}
          data-cy='card-image'
        />
      </ErrorBoundary>
    );
  }

  if (imageType === 'logo') {
    const IMG_SOURCE = src ? getLogo(src) : defaultImage;
    return (
      <ErrorBoundary nameOfComponent='core-lazyimage'>
        <img
          src={defaultImage || noCollageLogo}
          data-src={IMG_SOURCE}
          alt={alt || 'logo'}
          className={`lazyload blur-up ${className}`}
        />
      </ErrorBoundary>
    );
  }

  if (imageType === 'cardLogo') {
    const IMG_SOURCE = src ? getLogo(src) : defaultImage;
    return (
      <ErrorBoundary nameOfComponent='core-lazyimage'>
        <img
          alt={alt || 'logo'}
          data-src={IMG_SOURCE}
          src={defaultImage || noCollageLogo}
          className={`lazyload blur-up ${className}`}
        />
      </ErrorBoundary>
    );
  }

  if (dataSrcSet && sizes) {
    return (
      <ErrorBoundary nameOfComponent='core-lazyimage'>
        <img
          src={src}
          // data-sizes='auto'
          data-src={dataSrc}
          alt={alt}
          className={`lazyload blur-up ${className}`}
          data-srcset={dataSrcSet}
          sizes={sizes}
        />
      </ErrorBoundary>
    );
  }
  // if (imageSize) {
  //   return (
  //     <ErrorBoundary nameOfComponent='core-lazyimage'>
  //       <img src={src} alt={alt} width={imageSize} height='172px' />
  //     </ErrorBoundary>
  //   );
  // }

  return (
    <ErrorBoundary nameOfComponent='core-lazyimage'>
      <img
        alt={alt}
        width='100%'
        height='100%'
        data-src={src}
        src={defaultImage}
        className={`lazyload blur-up ${className}`}
      />
    </ErrorBoundary>
  );
};

export default LazyImage;
