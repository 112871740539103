import * as TYPES from './constants';
import {API} from 'config';

import {
  requestInstance,
  recieveInstance,
  errorInstance,
  recieveVersion,
} from './actions';

import {Services} from 'core/Services';
import {freemium} from 'data/institutionVersion';
const services = new Services();

const noOfBanners = 4;
const bannerField = 'banner';

const orderBannerImages = data => {
  const banner_details = data && data.banner_details;
  const company_banner_details = data && data.company_details;
  let finalData = data;
  if (Array.isArray(banner_details) && banner_details.length > 0) {
    const arr = [];
    const bnrfrmt = banner_details.map(i => i.banner_name);
    for (let i = 1; i <= noOfBanners; i++) {
      const index = bnrfrmt.indexOf(`${bannerField}${i}`);
      if (index !== -1) {
        const banner = banner_details[index];
        // removing this to make the link navigate in the same screen
        // if (
        //   banner &&
        //   banner.byline_text &&
        //   typeof banner.byline_text === 'string'
        // ) {
        //   banner.byline_text = banner.byline_text.replace(
        //     /<(a)([^>]+)>/,
        //     (a, b, c) => {
        //       return `<${b} target="_blank"${c}>`;
        //     },
        //   );
        // }
        arr.push(banner);
      }
    }
    finalData = {...finalData, banner_details: arr};
  }
  if (
    Array.isArray(company_banner_details) &&
    company_banner_details.length > 0
  ) {
    const arr = [];
    const bnrfrmt = company_banner_details.map(i => i.banner_index);
    for (let i = 1; i <= 10; i++) {
      const index = bnrfrmt.indexOf(i);
      if (index !== -1) {
        const banner = company_banner_details[index];
        arr.push(banner);
      }
    }
    finalData = {...finalData, company_details: arr};
  }
  return finalData;
};

const initialState = {request: false, version: {}};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.INSTANCE_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.INSTANCE_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: orderBannerImages(action.payload),
      };
    case TYPES.VERSION_SUCCESS: {
      let data = {};
      const isInstitution =
        action.payload?.super_type_name &&
        action.payload.super_type_name === 'Institution';
      if (
        isInstitution &&
        action.payload?.version_details &&
        action.payload.version_details === 'Freemium'
      ) {
        data = freemium;
      }

      return {
        ...state,
        request: false,
        error: false,
        version: data,
      };
    }
    case TYPES.CLEAR_INSTANCE:
      return {
        request: false,
        error: false,
      };
    case TYPES.INSTANCE_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

const parseBannerText = str => {
  if (str && typeof str === 'string') {
    var re = new RegExp('<a([^>]* )href="([^"]+)"', 'g');
    console.log('parseBannerText', str.matchAll(re));
    return str?.replace(re, ($1, $2, $3) => {
      if (
        $3 === '[[signup]]' ||
        $3 === '[[signin]]' ||
        $3 === '[[programs]]' ||
        $3 === '[[companies]]'
      ) {
        return `<a${$2}href="#" type='${$3}' onclick="openLink('${$3}')"`;
      }
      return $1;
    });
  }
  return str;
};

export const fetchInstance = () => (dispatch, getState) => {
  try {
    dispatch(requestInstance());
    let endPoint = `${API.srm.license}`;
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (!res?.error) {
        //to display splash screen little pause..
        console.log('instance', res);
        const banner_details = res && res.banner_details;
        if (Array.isArray(banner_details)) {
          banner_details.forEach(b => {
            b.byline_text = parseBannerText(b.byline_text);
          });
        }
        setTimeout(() => {
          dispatch(recieveInstance(res));
          dispatch(recieveVersion(res));
        }, 1000);
        return;
      }
      dispatch(errorInstance(res));
    });
  } catch (err) {
    dispatch(errorInstance(err));
  }
};
