import React from 'react';
import {
  Box,
  Link,
  Stack,
  IconButton,
  Typography,
  Container,
} from '@mui/material';
import {footerLinks, footerSocialLinks} from './menuItems';

const Footer = () => {
  return (
    <Box
      component='footer'
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: theme => theme.palette.background.paper,
        borderTop: theme => `1px solid ${theme.palette.divider}`,
        fontFamily: 'Inter',
      }}>
      <Container>
        {/* Top links */}
        <Stack
          direction={{xs: 'column', sm: 'row'}}
          spacing={{xs: 2, sm: 3}}
          justifyContent='center'
          alignItems='center'
          mb={2}
          sx={{fontSize: '12px'}}>
          {footerLinks.map(link => (
            <Link
              key={link.key}
              href={link.link}
              target={link.target}
              underline='none'
              color='inherit'>
              {link.label}
            </Link>
          ))}
        </Stack>

        {/* Bottom copyright and social icons */}
        <Stack
          direction={{xs: 'column', sm: 'row'}}
          spacing={2}
          justifyContent='center'
          alignItems='center'>
          <Typography
            variant='body2'
            sx={{fontFamily: 'Inter'}}
            color='text.secondary'
            align='center'>
            © GoEducate, Inc. All rights reserved. | Austin, TX
          </Typography>

          <Stack direction='row' spacing={1}>
            {footerSocialLinks.map((link, index) => (
              <IconButton key={index} href={link.link} target={link.target}>
                {link.icon}
              </IconButton>
            ))}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
