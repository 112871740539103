import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const footerSocialLinks = [
  {
    icon: <FacebookIcon />,
    link: 'https://www.facebook.com/GoEducate-Inc-130365025434596',
    target: '_blank',
  },
  {
    icon: <InstagramIcon />,
    link: 'https://www.instagram.com/goeducateinc/',
    target: '_blank',
  },
  {
    icon: <LinkedInIcon />,
    link: 'https://www.linkedin.com/company/goeducate/',
    target: '_blank',
  },
];

export const footerLinks = [
  {
    label: 'Accessibility',
    key: 'ACCESSIBILITY_STATEMENT',
    link: '/accessibility',
  },
  {
    label: "GoEducate's Weekly Giveaway",
    key: 'GIVEAWAY',
    link: '/giveaway',
  },
  {
    label: 'Report Abuse',
    key: 'REPORT_ABUSE',
    link: '/report-abuse',
  },
  {
    label: 'Privacy Policy',
    key: 'PRIVACY_POLICY',
    link: '/privacy-policy',
  },
  {
    label: 'Terms of Use',
    key: 'TERMS_OF_USE',
    link: '/terms-of-use',
  },
  {
    label: 'Contact Us',
    key: 'CONTACT_US',
    link: 'mailto:contactus@goeducate.com',
  },
];
