import React, {useEffect} from 'react';
import {useUser} from 'core/hooks';
import {styled} from '@mui/material/styles';
import {Box} from '@mui/material';
import NoDataContainer from '../NoDataContainer';
import {useHistory, Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  Button,
  BarChart,
  Accordion,
  ContentBox,
  ErrorBoundary,
  RequestErrorLoader,
} from 'mui-core';
import {getInstanceVersion} from 'redux/modules/instance';
import {getFitAssessmentSurvey} from 'redux/modules/occupation';
import {fetchFitAssessmentSurvey} from 'redux/modules/occupation/fitAssessment';
import {career} from 'data/dashboard.json';

const {InterestRating, exploreCareersBtnTxt, careerFitAssessmentSurvey} =
  career || {};
const {
  noResults: {title: noDataTitle, description: noDataDesc},
} = InterestRating;
const {accordionDesc, accordionHeader} = careerFitAssessmentSurvey;

const ExploreBtn = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  '& .exploreBtn:hover': {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
}));

const FitAssessmentSurvey = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {data: profileData} = useUser();
  const {
    data: fitAssessmentSurveyData,
    request: fitAssessmentReq,
  } = useSelector(getFitAssessmentSurvey);
  const {dashboard: {my_careers: {career_task_survey = false} = {}} = {}} =
    useSelector(getInstanceVersion) || {};

  const onLabelClick = label => {
    if (label) {
      const obj = fitAssessmentSurveyData.find(
        ele => ele.career_name === label,
      );
      history.push(
        `/career-details/${encodeURIComponent(label)}/${obj?.cip_code}`,
      );
    }
  };

  useEffect(() => {
    dispatch(fetchFitAssessmentSurvey(profileData?.student_uuid));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentBox elevation={0}>
      <Accordion
        defaultOpen
        outlined={false}
        header={accordionHeader}
        description={accordionDesc}>
        <ErrorBoundary
          typeOfUi='subPage'
          nameOfComponent='module-dashboard-career-careerTaskSurvey'>
          <RequestErrorLoader
            hideBackground
            body={{
              data: fitAssessmentSurveyData,
              request: fitAssessmentReq,
            }}
            overrideNoDataContainer={
              <NoDataContainer
                noDataDesc={noDataDesc}
                noDataTitle={noDataTitle}
                featureHidden={career_task_survey}
              />
            }>
            <ExploreBtn>
              <Link to='/careers'>
                <Button variant='outlined' className='exploreBtn'>
                  {exploreCareersBtnTxt}
                </Button>
              </Link>
            </ExploreBtn>
            <BarChart
              data={
                Array.isArray(fitAssessmentSurveyData)
                  ? fitAssessmentSurveyData?.map(i =>
                      i && i.score ? i.score : 0,
                    )
                  : []
              }
              labels={
                Array.isArray(fitAssessmentSurveyData)
                  ? fitAssessmentSurveyData?.map(i => i && i?.career_name)
                  : []
              }
              onLabelClick={onLabelClick}
            />
          </RequestErrorLoader>
        </ErrorBoundary>
      </Accordion>
    </ContentBox>
  );
};

export default FitAssessmentSurvey;
