import {useEffect, useState} from 'react';
import {
  Box,
  Grid,
  Radio,
  Stack,
  Select,
  Divider,
  MenuItem,
  RadioGroup,
  Typography,
  OutlinedInput,
  FormControlLabel,
} from '@mui/material';
import {LocationSearch} from 'mui-core';
import {createField} from 'mui-core/Form';
import jobsStaticData from 'data/jobs.json';

const {
  filterForm: {
    labels,
    jobTypes,
    placeholders,
    radiusOptions,
    indeedJobTypes,
    datePostedOptions,
    workArrangementOptions,
    indeedWorkArrangementOptions,
  },
} = jobsStaticData || {};

const LocalJobsFilterForm = ({
  form,
  source,
  filters,
  nearbyMSA,
  hideCompanyFilter,
}) => {
  const {setValue, getValues} = form;
  const [radius, setRadius] = useState(filters?.radius || '20');
  const [disableRadius, setDisableRadius] = useState(
    !!filters?.location === 'United States',
  );

  useEffect(() => {
    if (filters) {
      const {location, radius} = filters;

      if (location) {
        setValue('location', location);
        setDisableRadius(location === 'United States');
      }

      if (radius) {
        setRadius(radius);
        setValue('radius', radius);
      }
    }
  }, [filters]);

  const handleLocationChange = value => {
    setValue('location', value.location);
    setDisableRadius(value.location !== 'United States' ? false : true);
  };

  const onInputChange = value => {
    setValue('location', value.location);
  };

  const handleRadiusChange = event => {
    setRadius(event.target.value);
    setValue('radius', event.target.value);
  };

  const CompanyField = createField('company', {
    form,
    label: labels.company,
    defaultValue: getValues('company') || '',
  })(
    <OutlinedInput label={labels.company} placeholder={placeholders.company} />,
  );
  const filteredJobType =
    source === 'pi' || source === 'all' ? indeedJobTypes : jobTypes;
  const filteredWorkArrOptions =
    source === 'pi' || source === 'all'
      ? indeedWorkArrangementOptions
      : workArrangementOptions;

  return (
    <Stack spacing={3}>
      {createField('location', {
        form,
        rules: {
          required: [true, 'Location is mandatory field'],
        },
        defaultValue: getValues('location') || '',
      })(
        <LocationSearch
          calledFromForm
          LocationOptions={nearbyMSA}
          onInputChange={onInputChange}
          onLocationChange={handleLocationChange}
          filters={{location: getValues('location')}}
        />,
      )}
      <Box height='auto' marginTop={0}>
        {createField('radius', {
          form,
          label: 'Radius',
        })(
          <>
            <Select
              fullWidth
              label='Within'
              value={radius}
              disabled={disableRadius}
              placeholder='Select Radius'
              onChange={handleRadiusChange}>
              {radiusOptions &&
                Array.isArray(radiusOptions) &&
                radiusOptions.length > 0 &&
                radiusOptions.map(type => (
                  <MenuItem value={type.value}>{type.label}</MenuItem>
                ))}
            </Select>
          </>,
        )}
      </Box>
      {!hideCompanyFilter && CompanyField}
      <Box>
        <Typography mb={1} variant='h5' color='text.primary'>
          {labels.datePosted}
        </Typography>
        <Grid container>
          {createField('no_of_days', {
            form,
            defaultValue: getValues('no_of_days') || 0,
          })(
            <RadioGroup row>
              {datePostedOptions.map((item, index) => (
                // <Grid item xs={6} md={6} key={index}>
                <FormControlLabel
                  key={index}
                  sx={{width: 'calc(50% - 5px)'}}
                  value={item.value}
                  label={item.label}
                  control={<Radio />}
                />
                // </Grid>
              ))}
            </RadioGroup>,
          )}
        </Grid>
      </Box>
      <Divider />
      <Box>
        <Typography mb={1} variant='h5' color='text.primary'>
          {labels.jobType}
        </Typography>
        <Grid container>
          {createField('job_type', {
            form,
            defaultValue: getValues('job_type') || '',
          })(
            <RadioGroup row>
              {filteredJobType?.map((item, index) => (
                <FormControlLabel
                  key={index}
                  sx={{width: 'calc(50% - 5px)'}}
                  value={item.value}
                  label={item.label}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>,
          )}
        </Grid>
      </Box>
      <Divider />
      <Box>
        <Typography mb={1} variant='h5' color='text.primary'>
          {labels.workArrangement}
        </Typography>
        <Grid container>
          {createField('location_type', {
            form,
            defaultValue: getValues('location_type') || '',
          })(
            <RadioGroup row>
              {filteredWorkArrOptions?.map((item, index) => (
                <FormControlLabel
                  key={index}
                  sx={{width: 'calc(50% - 5px)'}}
                  value={item.value}
                  label={item.label}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>,
          )}
        </Grid>
      </Box>
    </Stack>
  );
};

export default LocalJobsFilterForm;
