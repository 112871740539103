import {useHistory, useLocation} from 'react-router';
import React, {useState, useEffect, useCallback} from 'react';
// components
import {LayoutContainer, VideoBanner} from 'mui-core';
import JobLayout from './Layout';
import HonestJobLayout from './HonestJobLayout';
import FilterForm from './components/FilterForm';
import {Stack, Box, Typography} from '@mui/material';
import RelatedSearch from './components/RelatedSearch';
// utils
import {useForm} from 'react-hook-form';
import {jobVideoBanner} from 'assets/images';
import jobsStaticData from 'data/jobs.json';
import {useTheme} from '@mui/material/styles';
import {useInstance, useOpportunitiesData} from 'core/hooks';

const {
  defaultLocation,
  bannerData: {bannerDesc, bannerTitle},
} = jobsStaticData || {};

const defaultParams = {
  page: 1,
  page_size: 16,
  sort: 'date_desc',
  job_source: 'all',
};

const Jobs = ({
  company = '',
  tabPage = false,
  search_query = '',
  showSearch = true,
  title = 'Job Finder',
  disableBGcolor = false,
  subTitle = 'Search for the perfect job and take the next step in your career journey.',
  relatedSearch = [],
}) => {
  const form = useForm();
  const history = useHistory();
  const location = useLocation();
  const {reset, handleSubmit} = form;
  const [filters, setFilters] = useState({});
  const [source, setSource] = useState('all');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [reqParams, setReqParams] = useState(defaultParams);
  const [initialFilters, setInitialFilters] = useState(filters);
  const {
    jobsData,
    clearJobs,
    fetchAllJobs,
    fetchHonestJobs,
  } = useOpportunitiesData();
  const {data: {data: jobsList = []} = {}, request: requestJobsList} =
    jobsData || {};
  const {
    data: {
      super_type_name = null,
      default_msa: {city = '', state_code = ''} = {},
    } = {},
  } = useInstance();
  const defaultLoc = super_type_name
    ? `${city ? city + ', ' : ''}${state_code}`
    : defaultLocation;
  const isCriminalJustice = super_type_name === 'Criminal Justice Organization';

  const resetObj = {
    title: '',
    company: '',
    work_arr: '',
    radius: '20',
    job_type: '',
    no_of_days: 0,
    location_type: '',
    job_source: 'all',
    location: initialFilters?.location || '',
  };

  const apiCall = (Obj, filters = null) => {
    if (filters['job_source'] === 'all' && isCriminalJustice) {
      filters['job_source'] = 'cos';
    }
    fetchAllJobs(Obj, filters);
  };

  const honestJobApiCall = (Obj, filters = null) => {
    fetchHonestJobs(Obj, filters);
  };

  const refetchData = (Obj, newFilters, loadMore) => {
    setReqParams(Obj);
    let updatedFilters = {...filters, ...newFilters};
    setFilters(updatedFilters);
    if (
      updatedFilters?.no_of_days === 0 ||
      updatedFilters?.no_of_days === null
    ) {
      delete updatedFilters['no_of_days'];
    }

    updatedFilters?.job_source === 'honest'
      ? honestJobApiCall(Obj, updatedFilters)
      : apiCall(Obj, updatedFilters, loadMore);
  };

  const onPageChange = page => {
    window.scrollTo({top: 660, behavior: 'smooth'});
    const Obj = Object.assign({}, reqParams, {
      page,
    });
    refetchData(Obj, filters, true);
  };

  useEffect(() => {
    let updatedFilters = {
      ...filters,
      location: defaultLoc,
    };
    const search = new URLSearchParams(location.search);
    const job_source = decodeURIComponent(search.get('source') || 'all');
    if (search_query) {
      updatedFilters['title'] = search_query;
    }
    if (company) {
      updatedFilters['company_name'] = company;
    }
    if (job_source) {
      updatedFilters['job_source'] = job_source;
      setSource(job_source);
    }
    if (isCriminalJustice) {
      if (!job_source) {
        updatedFilters['job_source'] = 'cos';
        setSource('cos');
      }
    }
    if (relatedSearch?.length > 0) {
      updatedFilters['title'] = relatedSearch[0];
    }
    setInitialFilters(updatedFilters);
    refetchData(reqParams, updatedFilters);
    return () => {
      clearJobs();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = useCallback(
    values => {
      let filterApply = false;
      let newFilters = {...filters, ...values};
      if (values) {
        const filterKeys = Object.keys(values);
        filterKeys.forEach(key => {
          if (values[key] || values[key] === '') {
            filterApply = true;
          }
          if (key === 'no_of_days') {
            const intVal = parseInt(values[key]);
            values[key] = intVal;
            newFilters[key] = intVal;
            if (values[key] === 0) {
              values[key] = null;
              newFilters[key] = null;
            }
            filterApply = true;
          }
        });
        if (filterApply) {
          const Obj = Object.assign({}, reqParams, {
            sort: 'date_desc',
            page: 1,
          });
          refetchData(Obj, newFilters);
          history.replace({
            search: '',
          });
          onDrawerClose();
        }
      }
    },
    [filters], // eslint-disable-line react-hooks/exhaustive-deps
  );

  // title search.
  const onTitleSearch = data => {
    if (data?.title) {
      onFinish(data);
    }
  };

  // filter search.
  const onShowFilter = value => {
    const updatedValue = {...value, job_source: source};
    onFinish(updatedValue);
  };

  // reset.
  const onReset = () => {
    reset(resetObj);
    setFilters(resetObj);
    refetchData(defaultParams, resetObj);
    onDrawerClose();
  };

  // updates filters according to job_source.
  const onSourceChange = e => {
    const {value = ''} = e?.target;
    setSource(value);
    reset({...resetObj, title: filters?.title, job_source: value});
    setFilters({...resetObj, title: filters?.title, job_source: value});
  };

  const onSelectCareerName = name => {
    refetchData(reqParams, {...filters, title: name});
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const onDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const dataSet = {
    data: jobsList,
    request: requestJobsList,
  };

  return (
    <LayoutContainer
      title={title}
      dataSet={dataSet}
      subTitle={subTitle}
      typeOfUi='fullPage'
      nameOfComponent='jobs'
      showResultCount={false}
      showSearch={showSearch}
      disableBGcolor={disableBGcolor}
      banner={
        !tabPage ? (
          <Box mb={4}>
            <VideoBanner bannerURL={jobVideoBanner}>
              <Stack gap={{xs: 2, md: 5}}>
                <Typography
                  color='white'
                  component='h1'
                  variant='header-lg-bold'
                  dangerouslySetInnerHTML={{
                    __html: bannerTitle,
                  }}
                />
                <Typography
                  color='white'
                  component='p'
                  variant='text-xl-regular'
                  dangerouslySetInnerHTML={{
                    __html: bannerDesc,
                  }}
                />
              </Stack>
            </VideoBanner>
          </Box>
        ) : null
      }
      searchProps={{
        filters,
        onDrawerOpen,
        name: 'title',
        onFilterReset: onReset,
        onFinish: value => onTitleSearch(value),
        searchPlaceholder: 'Search by job title or other keyword',
      }}
      filterForm={
        <FilterForm
          form={form}
          filters={filters}
          hideCompanyFilter={company}
          isDrawerOpen={isDrawerOpen}
          onDrawerClose={onDrawerClose}
          onResetClick={() => onReset()}
          onSourceChange={onSourceChange}
          source={filters['job_source'] || source}
          handleSubmit={handleSubmit(value => onShowFilter(value))}
        />
      }>
      {Array.isArray(relatedSearch) && relatedSearch.length > 0 && (
        <RelatedSearch
          relatedSearch={relatedSearch}
          onClick={onSelectCareerName}
        />
      )}

      {(source === 'honest' &&
        jobsList &&
        jobsList[0]?.job_source === 'honest') ||
      (jobsList && jobsList[0]?.job_source === 'honest') ? (
        <HonestJobLayout
          reqParams={reqParams}
          showSearch={showSearch}
          onPageChange={onPageChange}
        />
      ) : (
        <JobLayout
          reqParams={reqParams}
          showSearch={showSearch}
          onPageChange={onPageChange}
        />
      )}
    </LayoutContainer>
  );
};

export default Jobs;
