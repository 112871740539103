// import React from 'react';
// // hooks
// import {useInstance} from 'core/hooks';
// // components
// import {
//   Box,
//   Stack,
//   Container,
//   IconButton,
//   Typography,
//   useMediaQuery,
// } from '@mui/material';
// import {Link} from 'react-router-dom';
// import {ErrorBoundary, Button, BackgroundPaper, Grid} from 'mui-core';
// // utils
// import {FooterMenu, FooterMenuItem, FooterSocialLinks} from 'config';
// import {
//   primaryText,
//   poweredByText,
//   copyrightText,
//   secondaryText,
//   footerMenuTexts,
//   stayConnectedText,
//   newFooterMenuItems,
//   registeredLocationText,
// } from 'data/footer';
// import {getLogo} from 'core/utils';
// import {appLogo, appLogoWhite} from 'assets/images';
// import {styled, useTheme} from '@mui/material/styles';

import Footer from './AppFooter';

// const separator = ' | ';

// const StyledLogoContainer = styled(Box)(({}) => ({
//   img: {
//     height: '25px',
//     paddingLeft: '5px',
//   },
// }));

// const StyledWebLinks = styled(Typography)(({theme}) => ({
//   '&:hover': {
//     color: theme.palette.primary.main,
//   },
// }));

// const FooterMenuItemLists = ({name, target, link, anchor, isTab}) => {
//   return (
//     <>
//       {link && anchor ? (
//         <Box width='max-content'>
//           <Button
//             sx={{
//               justifyContent: 'left',
//             }}
//             href={link}
//             target={target}>
//             <StyledWebLinks
//               variant='h6'
//               color='text.primary'
//               fontSize={{xs: 12, sm: 14, md: 16}}>
//               {name}
//             </StyledWebLinks>
//           </Button>
//         </Box>
//       ) : link ? (
//         <Box width='max-content'>
//           <Link
//             style={{
//               justifyContent: 'left',
//             }}
//             to={link}>
//             <StyledWebLinks
//               px={1}
//               py={0.75}
//               variant='h6'
//               whiteSpace='nowrap'
//               color='text.primary'
//               fontSize={{xs: 12, sm: 14, md: 16}}>
//               {name}
//             </StyledWebLinks>
//           </Link>
//         </Box>
//       ) : (
//         <Typography component='span' variant='h6' color='text.primary'>
//           {name || ''}
//         </Typography>
//       )}
//     </>
//   );
// };

// const AppFooter = props => {
//   // let webUrl = '';
//   // let address = '';
//   const {data} = props;
//   const theme = useTheme();
//   const {isCriminalJustice} = useInstance();
//   const mobile = useMediaQuery(theme.breakpoints.only('xs'));
//   const isTab = useMediaQuery(theme.breakpoints.down('md'));
//   const FooterGlobalLogo =
//     theme?.palette?.mode === 'dark' ? appLogoWhite : appLogo;
//   // let footerLogoSrc = FooterGlobalLogo;
//   const redirect = (path, target) => window.open(path, target);
//   const pathNavigateTo = (path, target) => {
//     redirect(path, target);
//   };
//   // if (data?.institution_id) {
//   //   footerLogoSrc = getLogo(data.logo_cloudinary);
//   //   const addressArr = Object.values(data.physical_address);
//   //   const instanceAddress = addressArr.filter(res => res);
//   //   webUrl = data?.web_url || '';
//   //   address =
//   //     instanceAddress &&
//   //     instanceAddress.length > 0 &&
//   //     instanceAddress.join(', ');
//   // }

//   const filteredFooterMenuItem = !isCriminalJustice
//     ? FooterMenuItem
//     : FooterMenuItem.filter(item => item?.key !== 'RESOURCE_CENTER');
//   return (
//     <ErrorBoundary nameOfComponent='mui-core-footer'>
//       <Box sx={{background: 'white', zIndex: 800, position: 'relative'}}>
//         <BackgroundPaper
//           background={{
//             dark: '#222', // background color impact
//             //theme.palette.darkGray.main,
//             light: theme.palette.purplebg8perc.main,
//           }}
//           sx={{position: 'relative', zIndex: 700}}>
//           <Container>
//             <Stack py={3} spacing={{xs: 1, sm: 2}} width='100%'>
//               <Stack
//                 spacing={0}
//                 width='100%'
//                 alignItems='left'
//                 direction={{
//                   md: 'row',
//                   xs: 'column', // data?.institution_id ? 'row' : 'column',
//                 }}
//                 // spacing={{
//                 //   xs: data?.institution_id ? 2 : 0,
//                 //   lg: data?.institution_id ? 28 : 0,
//                 // }}
//               >
//                 <Stack
//                   spacing={2}
//                   direction='row'
//                   width={{xs: '100%', md: '30%', lg: '30%'}}>
//                   {/* width={data?.institution_id ? '60%' : '50%'}> */}
//                   <Box>
//                     <Typography variant='body2' color='text.primary'>
//                       {poweredByText}
//                     </Typography>
//                     <img
//                       alt='footer logo'
//                       src={FooterGlobalLogo} // footerLogoSrc for instance logo / Global
//                       height={mobile ? 32 : 48}
//                       width={mobile ? 'auto' : 182} // for desktop -> data?.institution_id ? 'auto' : 182
//                     />
//                   </Box>
//                   {/* {data?.institution_id && (
//                     <Stack width='100%' direction='row' alignItems='center'>
//                       <Stack spacing={1} width='100%'>
//                         <Typography variant='body1' color='text.primary'>
//                           {address || ''}
//                         </Typography>
//                         <a
//                           target='_blank'
//                           href={webUrl}
//                           rel='noopener noreferrer'>
//                           <StyledWebLinks variant='body1' color='primary.main'>
//                             {webUrl}
//                           </StyledWebLinks>
//                         </a>
//                       </Stack>
//                     </Stack>
//                   )} */}
//                 </Stack>
//                 <Stack
//                   width={{xs: '100%', md: '70%', lg: '70%'}}
//                   direction='row'
//                   alignItems='flex-start'
//                   // pt={{
//                   //   xs: data?.institution_id ? 0 : 1.5,
//                   //   sm: data?.institution_id ? 0 : 2,
//                   //   md: 0,
//                   // }}
//                   pt={{
//                     xs: 1.5,
//                     sm: 2,
//                     md: 0,
//                   }}
//                   // data?.institution_id ? 'flex-end' : 'flex-start'
//                   justifyContent='flex-start'>
//                   {/* {data?.institution_id ? (
//                     <StyledLogoContainer>
//                       <Box>
//                         <Typography variant='body2' color='text.primary'>
//                           {poweredByText}
//                         </Typography>
//                         <img src={FooterGlobalLogo} alt='footerlogosml' />
//                       </Box>
//                     </StyledLogoContainer>
//                   ) : ( */}
//                   <Stack spacing={{xs: 1, sm: 1, md: 1.5, lg: 1.5}}>
//                     <Typography
//                       variant='body1'
//                       color='text.primary'
//                       dangerouslySetInnerHTML={{__html: primaryText || ''}}
//                     />
//                     {/* <Typography
//                       variant='body1'
//                       color='text.primary'
//                       dangerouslySetInnerHTML={{
//                         __html: secondaryText || '',
//                       }}
//                     /> */}
//                   </Stack>
//                   {/* )} */}
//                 </Stack>
//               </Stack>
//               <Stack
//                 direction={{
//                   md: 'row',
//                   xs: 'column-reverse',
//                   // sm: 'column-reverse',
//                 }}
//                 width='100%'
//                 // spacing={{
//                 //   sm: 2,
//                 //   md: data?.institution_id ? 5 : 0,
//                 //   lg: data?.institution_id ? 36 : 20,
//                 // }}
//                 spacing={{
//                   sm: 2,
//                   md: 0,
//                   // lg: 20,
//                 }}>
//                 <Stack
//                   direction='row'
//                   alignItems='center'
//                   justifyContent='left'
//                   spacing={{sm: 2, md: 18}}
//                   width={{xs: '100%', md: '30%', lg: '30%'}}>
//                   <Stack spacing={1}>
//                     <Box pt={{xs: 1, sm: 0}}>
//                       <Typography
//                         variant='h5'
//                         color={
//                           theme.palette.mode === 'light'
//                             ? 'primary.dark'
//                             : 'white'
//                         }
//                         data-cy='footer-connect-text'>
//                         {stayConnectedText || ''}
//                       </Typography>
//                       <Stack direction='row'>
//                         {FooterSocialLinks?.map(
//                           ({name, link, target, mediaIcon}) => (
//                             <IconButton key={name} href={link} target={target}>
//                               <img src={mediaIcon} alt={name} />
//                             </IconButton>
//                           ),
//                         )}
//                       </Stack>
//                     </Box>
//                     {/* <Typography
//                       variant='body2'
//                       color='text.primary'
//                       data-cy='footer-copyright'>
//                       {copyrightText && `${copyrightText}${separator}`}
//                       {FooterMenu?.slice(2).map(({path, key}) => {
//                         const {[key]: {secondaryName = ''} = {}} =
//                           footerMenuTexts || {};
//                         return (
//                           <React.Fragment key={`__${secondaryName}__`}>
//                             {path ? (
//                               <Link to={path}>
//                                 {secondaryName === 'Address'}
//                               </Link>
//                             ) : (
//                               <Typography
//                                 variant='body2'
//                                 color='text.primary'
//                                 sx={{display: 'inline-block'}}>
//                                 {secondaryName || ''}
//                               </Typography>
//                             )}
//                           </React.Fragment>
//                         );
//                       })}
//                     </Typography> */}
//                     <Typography
//                       variant='body2'
//                       color='text.primary'
//                       data-cy='footer-copyright'>
//                       {copyrightText &&
//                         `${copyrightText}${separator}${registeredLocationText}`}
//                     </Typography>
//                   </Stack>
//                 </Stack>
//                 <Grid container>
//                   {filteredFooterMenuItem &&
//                     Array.isArray(filteredFooterMenuItem) &&
//                     filteredFooterMenuItem.map(
//                       ({key, link, target, anchor}, linkIdx) => {
//                         const matchingItem = newFooterMenuItems.find(
//                           item => item.key === key,
//                         );
//                         return (
//                           <Grid
//                             item
//                             lg={4}
//                             md={4}
//                             sm={4}
//                             xs={6}
//                             key={`footer-list-${linkIdx}-${key}`}>
//                             <FooterMenuItemLists
//                               link={link}
//                               isTab={isTab}
//                               target={target}
//                               anchor={anchor}
//                               name={matchingItem.name}
//                               handleRedirect={pathNavigateTo}
//                             />
//                           </Grid>
//                         );
//                       },
//                     )}
//                 </Grid>
//               </Stack>
//             </Stack>
//           </Container>
//         </BackgroundPaper>
//       </Box>
//     </ErrorBoundary>
//   );
// };

// export default AppFooter;

export default Footer;
